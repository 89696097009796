import React, { useEffect } from 'react';
import './pages.css';

const One = () => {
  useEffect(() => {
    document.title = 'One Hexus - Coming Soon';
  }, []);

  return (
    <div className="page-container">
      <h1>Introducing One Hexus</h1>
      <h2 className="subtitle">Revolutionizing Property Rentals</h2>
      <p className="hexus-text">
        As a leader in AI-driven web design and development, Hexus is excited to unveil our new website for  <strong>One Hexus</strong>. <br></br>Our mission is to revolutionize the property rental landscape by leveraging top notch technology to deliver seamless, top-tier experiences on platforms like Airbnb, Vrbo and directly through us.
      </p>
      <p className="hexus-text">
        With One Hexus, you can expect a comprehensive suite of property management solutions that not only optimize your rental operations but also exceed the expectations of your guests. We're committed to offering exceptional service, innovative features, and unparalleled support, making One Hexus the go-to choice for property owners and managers seeking a competitive edge.
      </p>
      <h2 className="coming-soon">Stay Tuned for Launch</h2>
      <p className="hexus-text">
        We're putting the finishing touches on One Hexus, and we can't wait to share it with you. Sign up for updates and be the first to know when our groundbreaking services go live.
      </p>
      {/* Consider adding a sign-up form or button to subscribe for updates here */}
    </div>
  );
};

export default One;
