import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { Footer, Blog, Possibility, Features, Hexus, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
import { Contact, Terms, Privacy, One, Plans } from './pages';


import './App.css';

const App = () => (
  <Router>
    <ScrollToTop /> {/* Add the ScrollToTop component */}
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
      </div>
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Brand />
            <Hexus />
            <Features />
            <Possibility />
            <CTA />
          </>
        } />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/onehexus" element={<One />} /> {/* Add this line for the new page */}
        <Route path="/plans" element={<Plans />} /> {/* Add this line for the new Plans page */}
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
