import React from 'react';
import ai from '../../assets/ai.png';
import './header.css';

const Header = () => (
  <div className="hx__header section__padding" id="home">
    <div className="hx__header-content">
      <h1 className="gradient__text">Transform Your Vision into Reality with Hexus Expertise</h1>
      <p>Discover the transformative power of cutting-edge technology and innovation. Hexus blends expertise and creativity to deliver exceptional results tailored to your unique business needs. Embrace the future with confidence and thrive in an ever-evolving digital landscape.</p>



      <div className="hx__header-content__people">
     
      </div>
    </div>

    <div className="hx__header-image">
      <img src={ai} />
    </div>
  </div>
);

export default Header;
