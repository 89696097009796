import React from 'react';
import hxLogo from '../../logo.svg';
import './footer.css';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="hx__footer section__padding">
    <div className="hx__footer-heading">
      <h1 className="gradient__text">Are you ready to take a step into the future?</h1>
    </div>

    <div className="hx__footer-btn">
      <a href="mailto:hi@hexushub.com">Contact Us</a>
    </div>

    <div className="hx__footer-links">
      <div className="hx__footer-links_logo">
        <img src={hxLogo} alt="hx_logo" />
        <p> <br />Code. <br /><br />Create. <br /><br />Collaborate.</p>
      </div>

      <div className="hx__footer-links_div">
        <h4>Hexus</h4>
        <Link to="/terms">Terms & Conditions</Link>
        <Link to="/privacy">Privacy Policy</Link>
        <Link to="/contact">Contact Us</Link>
      </div>

      <div className="hx__footer-links_div">
        <h4>Get in touch</h4>
        <p>100 S. Pointe Dr. <br />Miami Beach, FL 33139<br />United States</p>
        <p>+1 305.400.9299</p>
        <p>care@hexushub.com</p>
      </div>
    </div>

    <div className="hx__footer-copyright">
      <p>@2024 Hexus, LLC. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
