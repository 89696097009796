import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Swiss Precision and Innovation',
    text: 'Experience the excellence of Swiss precision in technology, as we deliver top-notch services and AI-powered tools to enhance your business and drive exceptional results.',
  },
  {
    title: 'Tailored Services for Every Industry',
    text: 'Our diverse range of services caters to various industries, ensuring that we can address the unique needs and challenges of each sector while providing tailored solutions to drive growth and success.',
  },
  {
    title: 'State-of-the-art AI-powered Tools',
    text: 'Harness the power of advanced AI technology to streamline your operations, optimize decision-making, and improve overall performance. Our AI-driven solutions are designed to maximize efficiency and provide a competitive edge.',
  },
  {
    title: 'Exceptional support, Every Step Of the Way',
    text: 'Our commitment to customer satisfaction is second to none. Our team of dedicated professionals is always available to guide you through our services and provide the necessary support to ensure seamless integration and smooth operation.',
  },
];

const Features = () => (
  <div className="hx__features section__padding" id="features">
    <div className="hx__features-heading">
      <h1 className="gradient__text">Experience the Future Today with Hexus – Unleash the Power of Innovation and Transform Your World.</h1>
      <p>Unlock the Potential of Advanced Technology with Hexus. <br></br>Contact us today to discover more about our exclusive services.</p>
    </div>
    <div className="hx__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
