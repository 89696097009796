import React from 'react';
import { Link } from 'react-router-dom';
import './cta.css';

const CTA = () => {
  return (
    <div className="hx__cta">
      <div className="hx__cta-content">
        <p>Experience the Hexus Advantage Today!</p>
        <h3>Connect with us & Begin a Journey to Transform Your Business with state-of-the-art AI solutions.</h3>
      </div>
      <div className="hx__cta-btn">
        <Link to="/contact">
          <button type="button">Contact Hexus</button>
        </Link>
      </div>
    </div>
  );
};

export default CTA;
