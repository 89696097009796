import React from 'react';
import { google, slack, atlassian, dropbox, shopify } from './imports';
import './brand.css';

const Brand = () => (
  <div className="hx__brand section__padding">
    
  </div>
);

export default Brand;
