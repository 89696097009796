import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="hx__possibility section__padding" id="possibility">
    <div className="hx__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="hx__possibility-content">
      
      <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1>
      <p>Discover the Hexus difference, where our passion for innovation and commitment to excellence converge to deliver bespoke solutions for your business needs. Harness the power of AI and our Swiss precision to unleash opportunities that surpass your wildest dreams. At Hexus, we turn your imagination into reality, shaping the future of your business together.</p>
      <h4>Contact us today to know more about our services.</h4>
    </div>
  </div>
);

export default Possibility;
