import React, { useEffect } from 'react';
import './pages.css';

const Privacy = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.id = 'termly-jssdk';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="page-container">
      <h1>Privacy Policy</h1>
      <div
        name="termly-embed"
        data-id="c63392b5-21f8-4072-9933-4ebf2d685abc"
        data-type="iframe"
      ></div>
    </div>
  );
};

export default Privacy;
