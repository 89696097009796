import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './pages.css';

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Replace these constants with your actual EmailJS values
    const SERVICE_ID = 'service_uwofutq';
    const TEMPLATE_ID = 'template_zwknv23';
    const PUBLIC_KEY = 'S2xF5PiWPvQ9FsFJm';
  
    emailjs
      .sendForm(
        SERVICE_ID,
        TEMPLATE_ID,
        formRef.current,
        PUBLIC_KEY
      )
      .then(
        () => {
          setLoading(false);
          alert('Thank you for your message. We will get back to you shortly.');
  
          setForm({
            name: '',
            email: '',
            message: '',
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);
  
          alert('Something went wrong, Please try again.');
        }
      );
  };
  

  return (
    <div className="page-container">
      <h1>Contact Us</h1>
      <p className="hexus-text">At Hexus, we're dedicated to providing exceptional customer support and innovative solutions for your needs.</p>
      <form onSubmit={handleSubmit} ref={formRef} className="mt-12 flex flex-col gap-8">
        <label className="flex flex-col">
          <span className="font-medium mb-4">Your Name</span>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
            placeholder="Full Name"
            className="py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
          />
        </label>
        <label className="flex flex-col">
          <span className="font-medium mb-4">Your Email</span>
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            placeholder="Email Address"
            className="py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
          />
        </label>
        <label className="flex flex-col">
          <span className="font-medium mb-4">Your Message</span>
          <textarea
            rows={7}
            name="message"
            value={form.message}
            onChange={handleChange}
            placeholder="Message"
            className="py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
          />
        </label>
        <button
          type="submit"
          className="py-3 px-8 rounded-xl outline-none w-fit font-bold shadow-md"
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>
    </div>
  );
};

export default Contact;
