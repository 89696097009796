import React from 'react';
import Feature from '../../components/feature/Feature';
import './Hexus.css';

const Hexus = () => (
  <div className="hx__Hexus section__margin" id="hexus">
    <div className="hx__Hexus-feature">
      <Feature title="Hexus Labs" text="Hexus Labs, the web development division of Hexus, excels in crafting bespoke digital solutions. Our expert team creates visually stunning, high-performance websites and applications, leveraging the latest technologies and a user-focused approach to meet diverse client needs across industries." />
    </div>
    <div className="hx__Hexus-heading">
      <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
      <p>Discover Hexus Labs Services</p>
    </div>
    <div className="hx__Hexus-container">
      <Feature title="Web, Mobile & Blockchain Development" text="Hexus Labs excels in creating custom web and mobile applications, as well as blockchain solutions. We leverage cutting-edge technologies, including Web 3.0 and popular frameworks, to develop responsive, high-performance, and secure digital products for various industries." />
      <Feature title="E-commerce, CMS & Marketing Solutions" text="We offer comprehensive e-commerce and content management system (CMS) solutions, along with targeted digital marketing services. Our expertise covers popular platforms and marketing strategies, ensuring clients receive tailored solutions that drive online growth and conversions." />
      <Feature title="UX/UI Design & Emerging Technologies" text="Hexus Labs prioritizes user experience (UX) and user interface (UI) design, crafting visually appealing and user-friendly products. We stay current with emerging technologies, incorporating innovations like AI and IoT, to create future-proof digital solutions for our clients." />
      <Feature title="Data Management & Analytics" text="Hexus Labs provides data management and analytics services, empowering businesses with actionable insights and data-driven decision-making. Our team is experienced in designing and implementing databases, data warehousing solutions, and utilizing analytics tools to help clients harness the power of their data, optimize processes, and improve overall performance." />
    </div>
  </div>
);

export default Hexus;
