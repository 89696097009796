import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import './pages.css';

const Plans = () => {
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  const checkPassword = (e) => {
    e.preventDefault();
    if (password === 'Senry2610$$') {
      setAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const createOrder = async (price) => {
    const response = await fetch('https://paypal-bf3q.onrender.com/create-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ price }),
    });
    const data = await response.json();
    return data.id;
  };

  const captureOrder = async (orderId) => {
    const response = await fetch('https://paypal-bf3q.onrender.com/capture-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ orderId }),
    });
    const data = await response.json();
    return data;
  };

  if (!authenticated) {
    return (
      <div className="page-container">
        <h1>Plans Page</h1>
        <form onSubmit={checkPassword}>
          <input
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }

  return (
    <div className="page-container">
      <h1>Our Plans</h1>
      <PayPalScriptProvider options={{ 'client-id': 'AQCqqeu0bNqeE4c-PkSBbJ3K-PyN94PO_wBehi1xhkkl8kle6eBIgTS8GEx51xJpjxnefocmKjmuVlyU' }}>
        <div className="plan">
          <h2>The Dev Plan</h2>
          <p>Price: $1269.00</p>
          <div className="paypal-button-container">
            <PayPalButtons
              createOrder={() => createOrder('1269.00')}
              onApprove={(data) => captureOrder(data.orderID)}
            />
          </div>
        </div>
        <div className="plan">
          <h2>For Start-Ups</h2>
          <p>Price: $2148.00</p>
          <div className="paypal-button-container">
            <PayPalButtons
              createOrder={() => createOrder('2148.00')}
              onApprove={(data) => captureOrder(data.orderID)}
            />
          
          </div>
        </div>
        <div className="plan">
          <h2>For Start-Ups</h2>
          <p>Price: $202.00</p>
          <div className="paypal-button-container">
            <PayPalButtons
              createOrder={() => createOrder('202.00')}
              onApprove={(data) => captureOrder(data.orderID)}
            />
          </div>
        </div>
        <div className="plan">
          <h2>Enterprise</h2>
          <p>Price: $3233.00</p>
          <div className="paypal-button-container">
            <PayPalButtons
              createOrder={() => createOrder('3233.00')}
              onApprove={(data) => captureOrder(data.orderID)}
            />
          </div>
        </div>
      </PayPalScriptProvider>
    </div>
  );
};

export default Plans;