import React, { useEffect } from 'react';
import './pages.css';

const Terms = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.id = 'termly-jssdk';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="page-container">
      <h1>Terms & Conditions</h1>
      <div
        name="termly-embed"
        data-id="70186c28-a451-4bbd-b365-df760bb13ea0"
        data-type="iframe"
      ></div>
    </div>
  );
};

export default Terms;
