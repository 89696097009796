import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../logo.svg';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="hx__navbar">
      <div className="hx__navbar-links">
        <div className="hx__navbar-links_logo">
          <img src={logo} />
        </div>
        <div className="hx__navbar-links_container">
          <p><Link to="/">Home</Link></p>
          <p><Link smooth to="/#hexus">Hexus Labs</Link></p>
          <p><Link smooth to="/#features">Our Services</Link></p>
          <p><Link smooth to="/#possibility">More</Link></p>
          <p><Link to="/contact">Contact Us</Link></p>
        </div>
      </div>

      <div className="hx__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="hx__navbar-menu_container scale-up-center">
            <div className="hx__navbar-menu_container-links">
              <p><Link to="/">Home</Link></p>
              <p><Link smooth to="/#hexus">Hexus Labs</Link></p>
              <p><Link smooth to="/#features">Our Services</Link></p>
              <p><Link smooth to="/#possibility">More</Link></p>
              <p><Link to="/contact">Contact Us</Link></p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
